import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

// import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import CodingLogos from "../components/codingLogos.js"
import PartnersLogos from "../components/partnerLogos.js"

import InternationalStudents from '../components/internationalStudents.js'
import Instalments from '../components/instalments.js'
import JSProjectsExamples from "../components/JSProjectsExamples.js";

// import {StripeProvider} from 'react-stripe-elements';
// import {Stripe} from 'stripe.js'
// import Checkout from '../components/checkout';

import Contacts from "../components/contactform"
import Covid from "../components/covid"

import ReqCur from "../components/reqCur"

import bannerImage from '../pics/game_development_online_bootcamp.jpg'
// import bannerImage from "../pics/javascript_fullstack_online_self_paced_bootcamp.png"

import Mike from "../pics/students/mike.jpg"
import Diane from "../pics/students/diane_sq.jpg"
import Carlos from "../pics/students/carlos_planchart.jpg"
import Yen from "../pics/students/yen.jpg"

import CR from "../pics/BestOnlineBadge2020.png"
import switchup from "../pics/switchup2020.png"

import Badges from '../components/badges'
import { el } from "date-fns/locale";

// import events from '../../assets/events.js'

const JSBootcampOnline = () => {
    let [duration, setDuration] = useState(null)
    const [cryptoCost, setCryptoCost] = useState(null)

    const [selected, setSelected] = useState('');
    const today = new Date()
    let footer = <p>You picked {selected && selected.toLocaleDateString()}.</p>;
    const dayOfWeekMatcher = { dayOfWeek: [0, 2, 3, 4, 5, 6] }
    const disabledDays = { from: new Date(2022, 3, 7), to: new Date(today.setDate(today.getDate() + 14)) }


    let handleChange = (event) => {
        setDuration(event.target.value)
        if (event.target.value === '3100') {
            setCryptoCost('f1b0eb94-4471-4de0-9d3d-6d2e06fa53e7') // 3100€
        } else if (event.target.value === '3800') {
            setCryptoCost('62072897-66d8-439b-8a18-3c04dd558c3a') // 3800
                   } 
        //            else if (event.target.value === '3900') {
        //     setCryptoCost('6d07247f-1fdd-431d-a048-b73b8cad997e') // 3900€
        // }
    }


    return (
        <div className="flexContent">
            <Helmet
                title="Game Development Online Mentored 1:1 Bootcamp in Barcelona Code School - learn C#, Unity, game design, storytelling, game art, publishing"
                meta={[
                    {
                        name: "description",
                        content:
                            "Game Development Bootcamp in Barcelona Code School. Intensive online course to become a game developer. Teaching C#, Unity, game design, storytelling, game art.",
                    },
                    {
                        name: "keywords",
                        content:
                            "unity bootcamp, gamedev bootcamp, learn unity, game development bootcamp, learn game development",
                    },
                    {
                        name: "viewport",
                        content: "width=device-width, initial-scale=1",
                    },
                    {
                        property: "og:title",
                        content:
                            "Game Development Online Mentored 1:1 Bootcamp in Barcelona Code School - learn C#, Unity, game design, storytelling, game art, publishing",
                    },
                    {
                        property: "og:description",
                        content:
                            "Game Development Bootcamp in Barcelona Code School. Intensive 9-week course to become a game developer. Teaching C#, Unity, game design, storytelling, game art.",
                    },
                    {
                        property: "og:image",
                        content:
                            "https://barcelonacodeschool.com/files/pics/game_development_online_bootcamp.jpg",
                    },
                    {
                        property: "og:url",
                        content:
                            "https://barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-online-bootcamp/",
                    },
                    { name: "twitter:card", content: "summary_large_image" },
                ]}
                script={[{ src: "https://js.stripe.com/v3/" }]}
            />
            <img
                className="banners flex1200 offsetImage"
                src={bannerImage}
                alt="Online Mentored 1:1 Game Development Bootcamp in Barcelona Code School"
            />

            <h1 className='transparentContainer flex225'>
                <span className="highlighter">Game Development Online Mentored 1:1 Bootcamp</span> in Barcelona
                Code School
            </h1>


            <h2 className='transparentContainer flex450'>Learn game development with Unity and C#</h2>

            <div className='transparentContainer flex450'>
                <h3>What is this bootcamp?</h3>
                <div className='whiteCard'>
                    <p>We prepared a <strong>Complete Game Development Bootcamp</strong> for those who are interested in the <strong className='highlighter'>full cycle of game production</strong>.</p>
                    <p>During this course you will try on <strong className='highlighter'>all the roles involved into a game development</strong> process, exactly how indie developers work, but of course the focus would be on the <strong className='highlighter'>game developer position</strong>.</p>
                    <p>Through the course you will be game designer, story writer, artist, developer and producer all-in-one!</p>
                    <p>As the focus is on development, you will spend most of your time mastering <strong className='highlighter'>C# and Unity</strong> engine. In the end you will have several <strong className='highlighter'>2D and 3D games finished</strong> and and we will help to create your <strong className='highlighter'>portfolio</strong> to showcase them and provide <strong className='highlighter'>job support</strong>!</p>
                </div>
            </div>

            <div className='transparentContainer flex225'>
                <p className='whiteCard'>Bootcamps since 2017 with continuous updates and improvements</p>
                <Badges activeLinks={false} />
            </div>

            <div className="transparentContainer">
                <h4>Share on social media</h4>
                <div className="socialsharebuttons">
                    <div className="socialshare" style={{ 'marginLeft': 0 }}>
                        <a
                            className="twitter-share-button "
                            href="https://twitter.com/intent/tweet?text='Game Development Online Mentored 1:1 Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/game-development-online-bootcamp"
                            data-size="large"
                        >
                            <button>tweet</button>
                        </a>
                    </div>

                    <div
                        className="fb-share-button socialshare"
                        data-href="https://developers.facebook.com/docs/plugins/"
                        data-layout="button"
                        data-size="small"
                    >
                        <a
                            target="_blank"
                            href="https://barcelonacodeschool.com/game-development-online-bootcamp"
                            className="fb-xfbml-parse-ignore"
                        >
                            <button>facebook</button>
                        </a>
                    </div>
                </div>
            </div>

            {/*<Badges activeLinks={false}/>*/}

            {/* <article className='transparentContainer flex600'>
				<h2>Get our awesome syllabus</h2>
				<div className='whiteCard'>
					<p>Subscribe to our mailing list and get the syllabus now.</p>
					<ReqCur
						course="Web / Mobile Development Online Mentored Bootcamp"
						link="https://barcelonacodeschool.com/files/BCS_Web_and_Mobile_Development_Bootcamp_Syllabus.pdf"
						courselink="https://www.barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-online-bootcamp/"
					/>
				</div>
			</article> */}

            <article className='transparentContainer flex225'>
                <h2>Check the syllabus</h2>
                <a href='https://barcelona-code-school.notion.site/Game-Development-Bootcamp-Syllabus-177501e9b08a4e54b7a3d7fa4cc6e387?pvs=4' target='blank' rel="noreferrer noopener"><button>Go to syllabus</button></a>
            </article>


            <article className='transparentContainer flex300'>
                <h2>Course format</h2>
                <ul className=' whiteCard neonList'>
                    <li><span className="highlighter">Mentored</span> remote training</li>
                    <li><span className='highlighter'>Personalized guidance</span> for each student</li>
                    <li>Learn <span className="highlighter">game development</span> from home</li>
                    <li><span className="highlighter">Flexible</span> schedule</li>
                    <li>Weekly personal <span className="highlighter">1:1 live sessions</span> with mentors</li>
                    <li><span className="highlighter">Support platform</span> for code-related questions</li>
                    <li>Community chat</li>
                    <li>The course is taught in <span className="highlighter">English</span></li>
                    <li>An industry-recognized <strong className="highlighter">graduation certificate</strong> upon completion</li>
                    <li>Job support</li>
                </ul>
            </article>



            <article className="transparentContainer flex600">
                <h2>What you'll learn</h2>
                <div className='whiteCard'>
                    {/*<CodingLogos />*/}
                    <h3>Core skills</h3>
                    <ul className='neonList'>
                        <li>How to build <strong className="highlighter">2D games</strong></li>
                        <li>How to build <strong className="highlighter">3D games</strong></li>
                        <li>How to build <strong className="highlighter">multiplayer games</strong></li>
                        <li><strong className="highlighter">C#</strong> – the language of game development</li>
                        <li><strong className="highlighter">Unity</strong> – one of the most powerful and used game engine and editor in the industry</li>
                    </ul>
                    <h3>Extra skills</h3>
                    <ul>
                        <li><span className="highlighter">Game design</span>: how to make a game structured and engaging</li>
                        <li><span className="highlighter">Story design</span>: rules and principles of storytelling</li>
                        <li><span className="highlighter">Game art</span>: how to create, generate or find the art you need</li>
                        <li><span className="highlighter">Game genres</span> and their specifics</li>
                        <li><span className="highlighter">Creating visuals</span> for publishing</li>
                        <li><span className="highlighter">User Interface design</span>: how to create UI of your game</li>
                        <li><span className="highlighter">User Experience (UX) for games</span>: how to not confuse your players</li>
                    </ul>
                </div>
            </article>



            <article className="transparentContainer flex900" >
                <h2>The outcomes</h2>
                <div className='whiteCard'>
                    <ul className='neonList'>
                        <li><span className="highlighter">Get hired as a game developer</span>. We will take you to the job-ready level so you will be able to apply for a junior game developer positions in any game development studio. Only in Catalonia there are <a href='https://www.devuego.es/bd/mapa-estudios/?comunidad=catalunya#listado' target="_blank">more than 150 of them</a>.</li>
                        <li><span className="highlighter">Go indie</span>. You will be able to start building your own games and publishing them. </li>
                        <li><span className="highlighter">Stay with us</span>. Stay for the internship with us to work on the game prototypes at <a href='https://soamazing.games/' rel="noopener noreferrer" target='_blank'>So Amazing Games</a> which are if published will allow you to stay for a full-time paid job.</li>
                    </ul>
                </div>
            </article>







            <article className="transparentContainer flex450">
                <h2>How does it work</h2>
                <div className='whiteCard'>
                    <ol>
                        <li>Choose a starting date and sign up from this page to reserve your place</li>
                        <li>Receive the pre-course materials to prepare before the bootcamp starts if needed</li>
                        <li>Get all the main course materials</li>
                        <li>We provide support via support platform, chat, track you progress and give feedback</li>
                        <li>Schedule weekly 1:1 sessions with your mentor to resolve any current issues, get explainations and ask any questions</li>
                        <li>The curriculum, projects, exercises and the outcomes are identical with the in-person version of the bootcamp</li>
                    </ol>
                </div>
            </article>


            {/* <InternationalStudents /> */}
            <article className='transparentContainer flex225'>
                <h2>International students</h2>
                <div className='whiteCard'>
                    <p>You are welcome!</p>
                    <p>A lot of our students are taking the course from abroad. The course is in English so there is no language barrier.</p>
                </div>
            </article>




            <article className="transparentContainer flex600">
                <h2>Tuition</h2>
                <div className='whiteCard'>
                    <h3 style={{ 'marginBottom': '.5em' }}>3100€: 9 weeks, full-time</h3>
                    <p>If you choose to do the course <strong>full-time</strong> you can finish it in <strong>9 weeks</strong>.</p>
                    <h3 style={{ 'marginBottom': '.5em' }}>3800€ 13 weeks, part-time</h3>
                    <p>If you want to do the bootcamp in a <strong>part-time mode</strong> this option will be the best.</p>
                    <details>
                        <summary><h4 style={{ 'display': 'inline' }}>Optional weekly extensions available</h4></summary>

                        <p>If you will need more time and mentors support to finish the bootcamp <strong>you can extend it on a weekly basis</strong> for 120€ per week which allows you to keep <strong>learning at your own pace</strong> while <strong>continuing getting all the support</strong> and guidance we provide.</p>
                        <p>In this way you can complete the bootcamp according to your schedule and learning speed – from full-time intensive 9-week duration, which is the same as we do with in-person group on campus, to a longer timeframe.</p>
                    </details>

                    {/*<p className="note">You can “freeze” your course for 1 or 2 weeks in case of some personal circumstances. It means that you don’t have access to support from mentors (1:1 sessions, support platform, slack consultations) but you still keep all the materials. All you need to do is notify us at least 1 week before the desired date of suspending the course. </p>*/}
                </div>
            </article>

            <article className="transparentContainer flex600">
                <h2 className="">
                    Access : start any Monday
                    <span
                        id="dates"
                        style={{
                            position: "relative",
                            top: "-50",
                            visibility: "hidden",
                        }}
                    >
                        .
                    </span>
                </h2>
                {/*<h4>Starting dates every week</h4>*/}
                {/*<div className='flexContent'>{courses.length > 0 && renderCourses()}</div>*/}


                <div className='flexContent whiteCard flex300'>
                    <div className="bottomSpace ">
                        <span>
                            <h2>{selected ? `You selected ${selected.toLocaleString("default", {
                                month: "long",
                            })} ${selected.getDate()}, ${selected.getFullYear()}` : 'Please choose your starting date'}</h2>
                            <DayPicker
                                mode="single"
                                selected={selected}
                                onSelect={setSelected}
                                // footer={footer}
                                weekStartsOn={1}
                                // disabled={dayOfWeekMatcher}
                                disabled={[disabledDays, dayOfWeekMatcher]}
                                fromYear={today.getFullYear()}
                                defaultMonth={new Date(today.setDate(today.getDate() + 14))}
                            />
                        </span>
                    </div>

                    <div className="bottomSpace flex300">
                        <h3>Game Development Online 1:1 Mentored Bootcamp</h3>
                        <p>Weekly 1:1 sessions, support platform, chat channel, text/video lessons</p>
                        <span>
                            <p>Online, mentored, in English</p>
                            {/*<p>{courses[0].fulldesc2}</p>*/}
                            <p>9 or 13 weeks, optional weekly extensions after that</p>
                            <select value={duration} onChange={(e) => handleChange(e)} style={{ marginBottom: '1em', width: '100%' }}>
                                <option value={null} disabled selected>Select the duration</option>
                                <option value="3100">9 weeks, 3100€</option>
                                <option value="3800">13 weeks, 3800€</option>
                            </select>
                            {selected && duration ? <Link
                                to="/register"
                                state={{
                                    course: 'Game Development Online 1:1 Mentored Bootcamp',
                                    date: selected && `${selected.toLocaleString("default", {
                                        month: "long",
                                    })} ${selected.getDate()}, ${selected.getFullYear()}`,
                                    cost: duration,
                                    cryptoID: cryptoCost,
                                    images: ['https://barcelonacodeschool.com/files/pics/game_development_online_bootcamp.jpg'],
                                }}
                            >

                                <button style={{ marginBottom: "1em" }}>Sign up</button> </Link> : <p className='highlighter'>Please choose a starting date and duration</p>}

                        </span>
                    </div>
                </div>

            </article>



            <JSProjectsExamples />












            <article className="transparentContainer flex450">
                <h2>Why Barcelona Code School?</h2>
                <div className='whiteCard'>
                    <ul>
                        <li><span className="highlighter">Cutting edge curriculum</span> tailored to job market and companies’ needs</li>
                        <li>Instructors, who <span className="highlighter">know how to teach</span></li>
                        <li>Fast track from beginner to job-ready coder</li>
                        <li>Best students/mentors ratio across online bootcamps to allow personalized approach </li>
                        <li><span className="highlighter">Job offers</span> for the students constantly coming from our <span className="highlighter">hiring partners</span></li>
                        <li>Life-time career support</li>
                    </ul>
                </div>
            </article>




            <article className='transparentContainer flex300'>
                <h2>Financing</h2>
                <div className='whiteCard'>
                    <p>We can assist in getting a student loan which usually has lower rates than consumer loans.</p>
                    <p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
                    <p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>

                    <Instalments />

                </div>
            </article>












            <article className='transparentContainer flex450'>
                <h2>FAQ</h2>
                <div className='whiteCard'>
                    <details>
                        <summary>
                            <strong>What computer would I need?</strong>
                        </summary>
                        <p>
                            Any operating system will do, Mac, Windows or Linux. If
                            you are thinking about buying a new one then the best
                            choice would be an Apple laptop: Macbook, Macbook Air or
                            Macbook Pro. On Windows laptop everything is doable as
                            well, simply some things work slower on Windows but it's
                            not going to prevent you from succeeding!
                        </p>
                    </details>

                    <details>
                        <summary>
                            <strong>What is the language of this course?</strong>
                        </summary>
                        <p>English.</p>
                    </details>

                    <details>
                        <summary>
                            <strong>Do I have to speak Spanish?</strong>
                        </summary>
                        <p>
                            For the course you do not need Spanish. Everybody at our
                            school speak English. To feel comfortable in Barcelona
                            Spanish would help but nowadays in most of the places
                            local people do speak English.
                        </p>
                    </details>

                    <details>
                        <summary>
                            <strong>What if I already know how to code?</strong>
                        </summary>
                        <p>
                            Amazing! For students who advance faster or have some
                            prior experience we have more challenging track with
                            hardcore exercises and job opportunities upon
                            graduation.
                        </p>
                    </details>

                    <details>
                        <summary>
                            <strong>
                                What qualification I will get from this course?
                            </strong>
                        </summary>
                        <p>
                            If completed successfully you will become a junior
                            full-stack developer. Meaning you will know how to build
                            web apps and be able to apply for the entry developer's
                            position.
                        </p>
                    </details>


                    <details>
                        <summary>
                            <strong>
                                How good would a typical graduate be after
                                completing the course?
                            </strong>
                        </summary>
                        <p>
                            You will learn the skills to begin as a junior
                            full-stack developer, will know how to build fully
                            functional web apps and have a solid foundation to
                            improve your expertise.
                        </p>
                    </details>

                    <details>
                        <summary>
                            <strong>Is there a pre-course?</strong>
                        </summary>
                        <p>Yes. We send you materials to study to come prepared!</p>
                    </details>
                </div>
            </article>


            <div className='flex450'>
                <Contacts
                    title={"Inquiry about the course"}
                    subject={"Inquiry about Game Development Online Bootcamp"}
                />
            </div>

            <article className='transparentContainer flex900'>
                <h2>Some of the companies where our graduates work or worked</h2>
                <div>
                    <PartnersLogos />
                </div>
            </article>

        </div>
    )
}

export default JSBootcampOnline
